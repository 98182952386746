<template>
  <div class="addBox">
    <div class="top">
      <el-button type="text" class="returnWeb" @click="returnWeb">返回店铺</el-button>
      <p class="title"><span>当前页面：</span>{{ shopinfo.name }}</p>
      <div class="topRight">
        <el-button class="returnWeb" @click="storage">保存</el-button>
      </div>
    </div>
    <div class="bot">
      <div class="leftMenu">
        <el-menu :default-active="active" class="el-menu-vertical-demo" :router="true">
          <el-menu-item v-for="(item, index) in routes" :key="index" :index="item.path">{{
            item.meta.name
          }}</el-menu-item>
        </el-menu>
      </div>
      <div class="rightContant">
        <keep-alive>
          <router-view ref="children"></router-view>
        </keep-alive>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
        shopinfo:{},
        routes:[],
        main_color:'#FF2626',
        active: ''
    }
  },
  created() {
    this.getShopinfo()
    let arr = this.$router.options.routes
    this.routes = arr.find((i) => i.path == this.$route.matched[0].path).children
    this.active = this.$route.path
  },
  watch:{
    $route(val){
        this.active = val.path
    }
  },
  methods: {
    getShopinfo(){
        this.$axios.post(this.$api.user.shopInfo).then((res) => {
            if (res.code == 0) {
                this.shopinfo = res.result.info
                this.getMainColor()
            } else {
                this.$message.error(res.msg)
            }
        })
    },
    // 返回后台
    returnWeb() {
        this.$router.push({
            path: '/index'
        })
    },
    // 点击保存配置
    storage() {
        this.$refs.children.savePageInfo()
    },
    // 获取主题颜色
    getMainColor(){
        this.$axios.post(this.$api.shopDiy.ShopColor, {}).then((res) => {
            if (res.code == 0) {
                let colorArr = res.result
                let indexItem = colorArr.find((item) => item.id == this.shopinfo.color_scheme)
                this.main_color = indexItem.main_color?indexItem.main_color:colorArr[0].main_color
            }
        })
    }
  },

}
</script>

<style lang="less" scoped>
.addBox {
  width: 100%;
  height: 100vh;
  overflow: auto;
  .top {
    width: 100%;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #f9f9f9;
    position: relative;
    border-bottom: 1px solid #e3e4e6;
    padding: 0 50px;
    flex-shrink: 0;
    position: sticky;
    z-index: 100;
    top: 0;
    .title {
      font-size: 14px;
      flex-shrink: 0;
      white-space: nowrap;
      span {
        color: #aeaeae;
      }
    }
    .topRight {
      display: flex;
      flex-wrap: nowrap;
    }
    .experience {
      margin-left: 10px;
      position: relative;
      img {
        width: 160px;
        height: 160px;
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        z-index: 100;
      }
    }
  }
  .bot {
    width: 100%;
    display: flex;
    .leftMenu {
      width: 150px;
      overflow: hidden;
      overflow-y: auto;
      background-color: #fff;
      .el-menu {
        height: 100%;
      }
    }
    .rightContant {
      width: calc(100% - 150px);
      min-height: 100%;
      background-color: #fff;
      // overflow-y: auto;
      .t_logo {
        text-align: center;
        margin: 20px 0;
      }
    }
  }
}
.temBox {
  display: flex;
}
</style>
